export default {
	title_1: "首页",
	title_2: "个人中心",
	title_3: "关于我们",
	title_4: "产品中心",
	title_5: "联系我们",
	title_6: "订单查询",
	title_7: "积分查询",
	title_8: "产品详情",
	title_9: "个人资料",
	title_10: "我的",
	title_11: "产品详情",
	nav_1: '关于我们',
	nav_2: '产品中心',
	nav_3: '联系我们',
	nav_4: '订单查询',
	input_1: '请输入邮箱',
	input_2: '请输入密码',
	input_3: '请输入验证码',
	input_4: '请输入手机号',
	input_5: '请输入报价单号',
	queding: '确定',
	denglu: '登陆',
	zhuche: '立即注册',
	login_tyoe: '切换登陆方式',
	zhanghao: '账号管理',
	order_tab_1: '报价单查询',
	order_tab_2: '采购单查询',
	order_tab_3: '查询物流',
	order_text_1: '报价单号',
	order_text_2: '报价时间',
	order_text_3: '订单详情',
	order_text_4: '采购单号',
	order_text_5: '采购时间',
	order_text_6: '物流单号',
	order_text_7: '物流时间',
	order_text_8: '订单详情',
	user_img: '头像',
	user_name: '姓名',
	fs_yzm:"发生验证码",
	cx_yzm:"重新发送",
	fs_tishi:"发送成功，邮箱请注意查收!",
	zaixiankefu:"在线客服",
	status_1:"待发货",
	status_2:"已发货",
	status_3:"运输中",
	status_4:"已签收",
	fuzhi:"复制",
	wuliuzhuangtai:"物流状态",
	chakan:"查看详情",
	home_1:"邮箱",
	home_2:"电话",
	home_3:"微信",
	home_4:"邮编",
	home_5:"公司地址",
	xcx:"小程序",
	ljxq:"了解详情",
	fanhui:"返回",
	cptd:"产品参数及其应用特点",
	zhizhao:"专业科学仪器及设备制造商"
}

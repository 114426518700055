const ajax = require("./ajax");

const URL = "https://hq112.huaqingsoft.com/api"

export const getCarousel = (type)=>ajax(URL + "/cms/cms/carousel",{type:2},"GET")

//关于我们,联系我们,协议
export const getConfig = () => ajax(URL + "/cms/cms/get_config", {}, "GET")

//商品分类
export const getGoodsCategory = (data) => ajax(URL + "/qianli/goods/category", {...data}, "GET")

//商品列表
export const getGoodsList = (data) => ajax(URL + "/qianli/goods/list", {...data}, "GET")

//商品详情
export const getGoodsInfo = (id) => ajax(URL + "/qianli/goods/info", {id}, "GET")


//修改信息
export const getUserProfile2 = (data) => ajax(URL + "/qianli/user/profile", {...data}, "POST")

//获取信息
export const getUserGetauth = () => ajax(URL + "/qianli/user/getauth", {}, "GET")

//订单列表
export const getOrderList = (data) => ajax(URL + "/qianli/order/list", {...data}, "GET")

//客服信息
export const getGetContact = (data) => ajax(URL + "/cms/cms/get_contact", {...data}, "GET")

//订单列表
export const getOrderSearch = (data) => ajax(URL + "/qianli/order/search", {...data}, "GET")

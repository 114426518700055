import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/goods-detail',
    name: 'goodsDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/GoodsDetailView.vue')
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUsView.vue')
  },
  {
    path: '/types',
    name: 'types',
    component: () => import(/* webpackChunkName: "about" */ '../views/TypesView.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "about" */ '../views/OrdersView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  },
})
export default router

<template>
  <div>
    <div class="all_top">
      <div class="bl all_w">
        <div class="left">
          <img :src="logo">
        </div>
        <div class="right">
          <div class="name" :class="{active:nav_url.count == '/'}" @click="getUrl('/')">{{ $t('title_1') }}</div>
          <div class="name" :class="{active:nav_url.count == '/about'}" @click="getUrl('/about')">{{
              $t('title_3')
            }}
          </div>
          <div class="name" :class="{active:nav_url.count == '/types'}" @click="getUrl('/types')">{{
              $t('title_4')
            }}
          </div>
          <div class="name" :class="{active:nav_url.count == '/contact-us'}" @click="getUrl('/contact-us')">
            {{ $t('title_5') }}
          </div>
          <div class="name" :class="{active:nav_url.count == '/orders'}" @click="getUrl('/orders')">{{
              $t('title_6')
            }}
          </div>
          <div class="name" @click="getImgCk">{{ $t("xcx") }}</div>
          <div class="type" @click="switchLanguage">{{ yuyan }}</div>
        </div>
      </div>
    </div>
    <div class="zhanwei"></div>
    <div class="model" v-if="is_img">
      <div class="bj" @click="is_img = !is_img"></div>
      <div class="content_img">
        <img :src="img_url" alt="">
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter, useRoute} from 'vue-router'
import {useCounterStore} from "@/store";
import {onMounted, ref} from "vue";
import i18n from "@/assets/lang";
import {getConfig, getGetContact} from "@/assets/ajax";



let nav_url = useCounterStore();
const router = useRouter()
const route = useRoute()
let yuyan = ref("EN")
let is_img = ref(false)
let img_url = ref("")
let logo = ref("")


onMounted(()=>{
  if(localStorage.getItem('lang') == "en"){
    yuyan.value = "中";
  }else{
    yuyan.value = "EN";
  }
  getData();

})

let getData = async () => {
  let result = await getConfig();
  logo.value = result.data[0].logo;
  img_url.value = result.data[0].mini_code;
}

let getImgCk = ()=>{
  is_img.value = true;
}

let getUrl = (url) => {
  nav_url.increment(url)
  router.push(url)
}

let switchLanguage = () => {
  let lang = ""
  if (yuyan.value === "EN") {
    yuyan.value = "中";
    lang = 'en';
    nav_url.inLangStatus(2);
    localStorage.setItem('lang', "en");
  } else {
    yuyan.value = "EN";
    lang = 'zh';
    nav_url.inLangStatus(1)
    localStorage.setItem('lang', "zh");
  }
  i18n.global.locale.value = lang;
}

</script>

<style lang="scss" scoped>

.model{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;

  .bj{
    width: 100%;
    height: 100vh;
    background-color: rgba(#000000,.6);
  }
  .content_img{
    width: 400px;
    height: 400px;
    position: absolute;
    left: calc(50% - 200px);
    top: calc(50% - 200px);

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


.zhanwei {
  width: 100%;
  height: 63px;
}

.all_top {
  background-color: #fff;
  box-shadow: 0 0 10px #dbdbdb;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .bl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    .left {
      img {
        width: 50px;
        height: 50px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .name {
        padding: 22px 0;
        width: 90px;
        margin-right: 10px;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
        position: relative;
      }

      .active {
        color: #518C89;

        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #518C89;
          box-shadow: 0 0 10px #518C89;
          border-radius: 10px;
        }
      }

      .type {
        width: 25px;
        height: 25px;
        font-size: 12px;
        background-color: #518C89;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
</style>

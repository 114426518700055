export default {
	title_1: "Homepage",
	title_2: "Personal Center",
	title_3: "About our company",
	title_4: "Product Center",
	title_5: "Contact Us",
	title_6: "Purchase Order Query",
	title_7: "Points Query",
	title_8: "Product Details",
	title_9: "Personal Information",
	title_10: "My",
	title_11: "Product Details",
	nav_1: 'About Us',
	nav_2: 'Product Center',
	nav_3: 'Contact us',
	nav_4: 'Purchase Order Query',
	input_1: 'Please enter your e-mail address',
	input_2: 'Please enter your password',
	input_3: 'Please enter the verification code',
	input_4: 'Please enter your phone number',
	input_5: 'Please enter the purchase order number',
	queding: 'OK',
	denglu: 'Login',
	zhuche: 'Register',
	login_tyoe: 'Switch login method',
	zhanghao: 'Account Management',
	order_tab_1: 'Quotation query',
	order_tab_2: 'Purchase order query',
	order_tab_3: 'Query the logistics',
	order_text_1: 'Quotation number',
	order_text_2: 'Quotation time',
	order_text_3: 'Purchase Order details',
	order_text_4: 'Purchase Order number',
	order_text_5: 'Purchase time',
	order_text_6: 'Logistics tracking number',
	order_text_7: 'Logistics time',
	order_text_8: 'Order details',
	user_img: 'Your profile picture',
	username: 'Your Name',
	fs_yzm:"Code",
	cx_yzm:"Resend",
	fs_tishi:"Sent successfully, please check your email!",
	zaixiankefu:"Online service",
	status_1:"To be delivered",
	status_2:"Shipped",
	status_3:"In transit",
	status_4:"Have been received",
	fuzhi:"Copy",
	wuliuzhuangtai:"Logistics status",
	chakan:"View content",
	home_1:"Mail",
	home_2:"Telephone",
	home_3:"WeChat",
	home_4:"Post code",
	home_5:"Company address",
	xcx:"Applets",
	ljxq:"Learn more",
	fanhui:"return",
	cptd:"Product parameters and application characteristics",
	zhizhao:"Professional scientific instrument and equipment manufacturer"
}

import {createApp} from 'vue'
import {createPinia} from 'pinia'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./assets/lang/index";



createApp(App).use(createPinia()).use(store).use(i18n).use(router).mount('#app')

import {createI18n} from "vue-i18n";
import zh from "./zh.js"
import en from "./en.js"


// 设置默认语言为中文
const defaultLang = 'zh'
// 设置当前语言
let currentLang = window.localStorage.getItem('lang') || defaultLang
// 引入当前语言的包
const messages = require(`./${currentLang}.js`)

const i18n = createI18n({
    locale: currentLang, // 设置当前语言
    legacy: false,
    globalInjection: true,
    messages: {
        zh: zh,
        en: en,
    }, // 设置语言环境的信息
})

export  default i18n

import {defineStore} from 'pinia'

export const useCounterStore = defineStore('counter', {
    state: () => {
        return {
            count: localStorage.getItem('count') || "/",
            lang: localStorage.getItem('lang_status') || 1,
            kefu: localStorage.getItem('kefu') ? JSON.parse(localStorage.getItem('kefu')) : {},
        }
    },
    // 也可以这样定义
    // state: () => ({ count: 0 })
    actions: {
        increment(name) {
            this.count = name;
            localStorage.setItem('count', this.count);
        },
        inLangStatus(num) {
            this.lang = num;
            localStorage.setItem('lang_status', this.lang);
        },
        inKefu(content) {
            this.kefu = content
            localStorage.setItem('kefu', JSON.stringify(this.kefu));
        },
    },
})

<template>
  <div class="all_bom">
    <div class="all_block all_w">
      <div class="top">
        <p>{{ $t('title_1') }}</p>
        <p>{{ $t('title_3') }}</p>
        <p>{{ $t('title_4') }}</p>
        <p>{{ $t('title_5') }}</p>
        <p>{{ $t('title_6') }}</p>
      </div>
      <div class="bom">
        <div class="item">
          <img src="../assets/imags/004.png">
          <p>{{ $t("home_3") }}：{{ StoreMy.kefu.name }}</p>
        </div>
        <div class="item">
          <img src="../assets/imags/005.png">
          <p>{{ $t("home_2") }}：{{ StoreMy.kefu.mobile }}</p>
        </div>
        <div class="item">
          <img src="../assets/imags/006.png">
          <p>{{ $t("home_4") }}：{{ StoreMy.kefu.zipcode }}</p>
        </div>
        <div class="item">
          <img src="../assets/imags/007.png">
          <p>{{ $t("home_1") }}：{{ StoreMy.kefu.email }}</p>
        </div>
        <div class="item">
          <img src="../assets/imags/008.png">
          <p>{{ $t("home_5") }}：{{ StoreMy.kefu.address }}</p>
        </div>
      </div>
      <div class="banbenhao" v-if="StoreMy.lang == 1">
        {{ info.filings }}
      </div>
      <div class="banbenhao" v-else>
        {{ info.filings_eng }}
      </div>
    </div>
  </div>
</template>

<script setup>

import {onMounted, ref} from "vue";
import {getGetContact,getConfig} from "@/assets/ajax";
import {useCounterStore} from "@/store";

let StoreMy = useCounterStore()
let info = ref({})

onMounted(() => {
  getData();
})

let getData = async () => {
  let result = await getGetContact();
  StoreMy.inKefu(result.data[0]);
  let result2 = await getConfig();
  info.value = result2.data[0]
}

</script>

<style lang="scss" scoped>
.all_bom {
  background-color: #111111;

  .all_block {
    margin: 0 auto;
    color: #fff;

    .top {
      display: flex;
      align-items: center;
      padding: 25px 0;
      border-bottom: 1px solid #c4c4c4;

      p {
        margin-right: 80px;
      }
    }

    .bom {
      padding: 20px 0;
      border-bottom: 1px solid #c4c4c4;

      .item {
        display: flex;
        align-items: center;
        padding: 3px 0;

        p {
          font-size: 12px;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }

    .banbenhao {
      padding: 20px 0;
      text-align: center;
    }
  }
}
</style>
